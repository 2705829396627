import * as React from "react";
import { animated, useChain, useSpring, useSpringRef } from "@react-spring/web";
/** @ts-ignore */
import useScreenOrientation from "react-hook-screen-orientation";
import { Station } from "../pages";
import "twin.macro";
import DoubleArrow from "./DoubleArrow";
import TripleArrow from "./TripleArrow";
import useOrientation from "../hooks/useOrientation";
import Trees from "./Trees";
import Stars from "./Stars";
import { rightAnswers } from "../data/game";
import Music from "./Music";

export type Coordinates = { x: number; y: number };

type BadgeConfig = {
  id: number;
  position: {
    landscape: Coordinates;
    portrait: Coordinates;
  };
  placeholder: {
    d: string;
  };
  won: {
    href: string;
  };
};

const badgesConfig: BadgeConfig[] = [
  {
    id: 1,
    position: {
      landscape: {
        x: 66,
        y: 117,
      },
      portrait: {
        x: 66,
        y: 117,
      },
    },
    placeholder: {
      d: "M56.6763 27.848V27.0936L55.9815 27.3875L35.4595 36.0675L35.0145 36.2557L35.1874 36.707L38.0394 44.147L38.2258 44.6332L38.7041 44.4272L46.5003 41.0697V71V71.5H47.0003H56.1763H56.6763V71V27.848Z",
    },
    won: {
      href: "/badges/badge01.png",
    },
  },
  {
    id: 2,
    position: {
      landscape: {
        x: 175,
        y: 215,
      },
      portrait: {
        x: 224,
        y: 194,
      },
    },
    placeholder: {
      d: "M64.7669 62.258V61.758H64.2669H52.0521L55.7562 56.1475L55.7565 56.1469C60.5121 48.9209 62.9069 45.074 62.9069 39.814C62.9069 35.8807 61.5595 32.7288 59.1193 30.5661C56.6869 28.4103 53.2243 27.286 49.0769 27.286C43.5832 27.286 39.9034 28.3694 34.571 31.3806L34.1399 31.6241L34.3791 32.0575L38.3471 39.2495L38.5932 39.6956L39.0345 39.4412C42.627 37.371 45.3938 36.718 48.0849 36.718C49.8341 36.718 50.9797 37.1623 51.6864 37.8293C52.3894 38.4929 52.7309 39.4458 52.7309 40.62C52.7309 41.8222 52.3269 43.283 51.6266 44.8662C50.9296 46.4421 49.955 48.1039 48.8518 49.6974L48.8514 49.698L34.3434 70.716L33.8022 71.5H34.7549H64.2669H64.7669V71V62.258Z",
    },
    won: {
      href: "/badges/badge02.png",
    },
  },
  {
    id: 3,
    position: {
      landscape: {
        x: 279,
        y: 74,
      },
      portrait: {
        x: 38,
        y: 272,
      },
    },
    placeholder: {
      d: "M34.4546 31.8422L34.1026 32.0921L34.2984 32.4768L37.8324 39.4208L38.0767 39.9009L38.5373 39.6215C42.2434 37.3736 45.0681 36.718 48.26 36.718C50.2016 36.718 51.6155 37.1815 52.5323 37.8984C53.4369 38.6057 53.898 39.5894 53.898 40.744C53.898 42.4003 53.2945 43.5082 52.2675 44.2244C51.2098 44.962 49.6395 45.328 47.64 45.328H43.238H42.738V45.828V53.516V54.016H43.238H47.702C50.2314 54.016 52.0359 54.4604 53.195 55.2472C54.3215 56.0119 54.89 57.1346 54.89 58.662C54.89 60.1841 54.3104 61.2447 53.2462 61.9497C52.1479 62.6771 50.4807 63.06 48.26 63.06C44.753 63.06 41.8693 62.4019 38.1704 60.0976L37.7155 59.8142L37.4643 60.2877L33.6823 67.4177L33.476 67.8066L33.8357 68.0605C38.145 71.1024 42.9604 72.492 48.446 72.492C53.3816 72.492 57.5276 71.3613 60.4533 69.2012C63.3961 67.0285 65.066 63.8403 65.066 59.84C65.066 57.3852 64.5714 55.2149 63.4614 53.3459C62.471 51.6782 61.0078 50.2793 59.0282 49.1362C62.4033 46.7833 64.012 43.5351 64.012 39.938C64.012 36.1622 62.4576 32.9811 59.7201 30.7539C56.9915 28.5339 53.1203 27.286 48.508 27.286C42.9353 27.286 38.3719 29.0623 34.4546 31.8422Z",
    },
    won: {
      href: "/badges/badge03.png",
    },
  },
  {
    id: 4,
    position: {
      landscape: {
        x: 377,
        y: 176,
      },

      portrait: {
        x: 76,
        y: 451,
      },
    },
    placeholder: {
      d: "M58.9529 54.07V28.84V28.34H58.4529H47.9129H47.6386L47.4912 28.5713L28.3332 58.6413L28.2549 58.7643V58.91V62.382V62.882H28.7549H48.9009V71V71.5H49.4009H58.4529H58.9529V71V62.882H62.6689H63.0239L63.1409 62.5468L65.8689 54.7348L66.1011 54.07H65.3969H58.9529ZM49.2109 54.07H41.1934L49.2109 41.358V54.07Z",
    },
    won: {
      href: "/badges/badge04.png",
    },
  },
  {
    id: 5,
    position: {
      landscape: {
        x: 475,
        y: 48,
      },
      portrait: {
        x: 245,
        y: 364,
      },
    },
    placeholder: {
      d: "M47.8369 43.034H47.5794L48.0446 38.02H61.0429H61.4033L61.5172 37.6781L64.4312 28.9361L64.6506 28.278H63.9569H40.1489H39.6916L39.6509 28.7335L37.6669 50.9295L37.6146 51.5146L38.2005 51.4727C39.0624 51.4112 40.1654 51.35 41.3269 51.35C45.076 51.35 48.4481 51.6011 50.8712 52.548C52.074 53.018 53.0128 53.6484 53.6515 54.4771C54.2855 55.2996 54.6529 56.3548 54.6529 57.732C54.6529 59.4762 54.1968 60.7702 53.2033 61.6419C52.1954 62.5264 50.5414 63.06 47.9609 63.06C45.3397 63.06 42.6401 62.4118 38.9253 60.0976L38.4764 59.818L38.2224 60.2818L34.3164 67.4118L34.1101 67.7884L34.4512 68.0492C38.7698 71.3517 43.3463 72.492 48.0849 72.492C53.4047 72.492 57.6307 71.1225 60.532 68.5202C63.441 65.911 64.9529 62.1211 64.9529 57.422C64.9529 53.82 63.852 50.2079 61.1241 47.4961C58.394 44.7822 54.0984 43.034 47.8369 43.034Z",
    },
    won: {
      href: "/badges/badge05.png",
    },
  },
];

const tripleArrow = {
  landscape: {
    x: 193,
    y: 86,
  },
  portrait: {
    x: 160,
    y: 265,
  },
};

const trees = {
  landscape: {
    x: 24,
    y: 267,
  },
  portrait: {
    x: 30,
    y: 566,
  },
};

const stars = {
  landscape: {
    x: 325,
    y: 18,
  },
  portrait: {
    x: 224,
    y: 91,
  },
};

const music = {
  landscape: {
    x: 479,
    y: 278,
  },
  portrait: {
    x: 234,
    y: 543,
  },
};

const doubleArrows = {
  landscape: [
    {
      x: 5,
      y: 81,
    },
    {
      x: 39,
      y: 113,
    },
    {
      x: 329,
      y: 295,
    },
    {
      x: 602,
      y: 167,
    },
  ],
  portrait: [
    {
      x: 5,
      y: 81,
    },
    {
      x: 39,
      y: 113,
    },
    {
      x: 323,
      y: 299,
    },
    {
      x: 62,
      y: 399,
    },
  ],
};

const size = {
  landscape: {
    width: 640,
    height: 360,
  },
  portrait: {
    width: 360,
    height: 640,
  },
};

type WalkedWayConfig = {
  d: string;
  strokeDashoffset: number;
  strokeDasharray: string;
};

const walkedWay: {
  landscape: WalkedWayConfig[];
  portrait: WalkedWayConfig[];
} = {
  landscape: [
    {
      d: "M116 196V249C116 257.837 123.163 265 132 265H196",
      strokeDashoffset: 143,
      strokeDasharray: "143 300",
    },
    {
      d: "M225 236L225 140C225 131.163 232.163 124 241 124L301 124",
      strokeDashoffset: 182,
      strokeDasharray: "182 300",
    },
    {
      d: "M329 153L329 210C329 218.837 336.163 226 345 226L398 226",
      strokeDashoffset: 136,
      strokeDasharray: "136 300",
    },
    {
      d: "M455 226L509 226C517.837 226 525 218.837 525 210L525 126",
      strokeDashoffset: 164,
      strokeDasharray: "164 300",
    },
  ],
  portrait: [
    {
      d: "M116 194V228C116 236.837 123.163 244 132 244H247",
      strokeDashoffset: 175,
      strokeDasharray: "175 300",
    },
    {
      d: "M274 272L274 306C274 314.837 266.837 322 258 322L116 322",
      strokeDashoffset: 202,
      strokeDasharray: "202 300",
    },
    {
      d: "M59.5 322H31C22.1634 322 15 329.163 15 338V485C15 493.837 22.1634 501 31 501H98",
      strokeDashoffset: 293,
      strokeDasharray: "293 300",
    },
    {
      d: "M126 473V430C126 421.163 133.163 414 142 414H268",
      strokeDashoffset: 195,
      strokeDasharray: "195 300",
    },
  ],
};

const roundIds = Object.keys(rightAnswers);

function Map({
  state = [],
  station = 0,
  ...props
}: {
  state?: Station[];
  station?: number;
}) {
  const orientation = useOrientation();
  const stateById = state.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {},
  );
  const wonAnswers = roundIds.filter(
    (id) =>
      !!stateById[id as keyof typeof stateById] &&
      stateById[id as keyof typeof stateById].won,
  ).length;

  const animations = badgesConfig.map((config: BadgeConfig, index: number) => {
    const isFirst = index === 0;
    const hasPreviousStation =
      !isFirst && !!stateById[(config.id - 1) as keyof typeof stateById];
    const currentState: Station | undefined =
      stateById[config.id as keyof typeof stateById];
    const isTouched = !!currentState;
    // const hasNextStation = !!stateById[(index + 2) as keyof typeof stateById];
    const isCurrent = station === config.id;

    const refs = [];

    const thisAnimations: Record<string, any> = {
      config,
    };

    if (!isFirst) {
      // Path animation
      const pathRef = useSpringRef();
      const strokeDashoffset =
        walkedWay[orientation][config.id - 2].strokeDashoffset;
      thisAnimations.pathStyles = useSpring({
        ref: pathRef,
        config: {
          duration: 1750,
        },
        from: {
          strokeDashoffset:
            hasPreviousStation && !isCurrent && isTouched
              ? 0
              : strokeDashoffset,
        },
        to: {
          strokeDashoffset:
            hasPreviousStation && isTouched ? 0 : strokeDashoffset,
        },
      });
      refs.push(pathRef);
    }

    // Placeholder animation
    const placeholderRef = useSpringRef();
    thisAnimations.placeholderStyles = useSpring({
      ref: placeholderRef,
      config: {
        duration: 150,
      },
      from: {
        scale: !isCurrent && isTouched ? 0 : 1,
      },
      to: {
        scale: !isTouched ? 1 : 0,
      },
    });
    refs.push(placeholderRef);

    // Badge animation
    const badgeRef = useSpringRef();
    thisAnimations.badgeStyles = useSpring({
      ref: badgeRef,
      config: {
        mass: 0.9,
        tension: 180,
        friction: 5,
        precision: 0.01,
      },
      from: {
        scale: isTouched && currentState?.won && !isCurrent ? 1 : 0,
      },
      to: {
        scale: isTouched && currentState?.won ? 1 : 0,
      },
    });
    refs.push(badgeRef);

    // Fail animation
    const failRef = useSpringRef();
    thisAnimations.failStyles = useSpring({
      ref: failRef,
      config: {
        mass: 0.9,
        tension: 180,
        friction: 5,
        precision: 0.01,
      },
      from: {
        scale: isTouched && currentState?.failed && !isCurrent ? 1 : 0,
      },
      to: {
        scale: isTouched && currentState?.failed ? 1 : 0,
      },
    });
    refs.push(failRef);

    useChain(refs);

    return thisAnimations;
  });

  return (
    <svg
      width={size[orientation].width}
      height={size[orientation].height}
      viewBox={`0 0 ${size[orientation].width} ${size[orientation].height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      tw="pointer-events-none"
      {...props}
    >
      {/* walked way */}
      <g tw="stroke-secondary stroke-[16px]" strokeLinecap="round">
        {animations.map(({ pathStyles }, index) => {
          if (!pathStyles) {
            return null;
          }
          const { strokeDashoffset, ...otherProps } =
            walkedWay[orientation][index - 1];
          return (
            <animated.path key={index} style={pathStyles} {...otherProps} />
          );
        })}
      </g>
      {/* way */}
      <g tw="stroke-on-default" strokeDasharray="4 3">
        {walkedWay[orientation].map(({ d }, index) => (
          <path d={d} key={index} />
        ))}
      </g>
      {/* big tree */}
      <g tw="stroke-on-default fill-default">
        <Trees {...trees[orientation]} />
      </g>
      {/* stars */}
      <g
        tw="stroke-on-default"
        transform={`translate(${stars[orientation].x} ${stars[orientation].y})`}
      >
        <Stars amount={wonAnswers} small={orientation === "portrait"} />
      </g>
      {/* music section */}
      <g
        tw="stroke-on-default"
        transform={`translate(${music[orientation].x} ${music[orientation].y})`}
      >
        <Music />
      </g>
      {/* triple arrow */}
      <g tw="stroke-on-default">
        <TripleArrow {...tripleArrow[orientation]} />
      </g>
      {/* double arrows */}
      <g tw="stroke-on-default">
        {doubleArrows[orientation].map((props, index) => (
          <DoubleArrow {...props} key={index} />
        ))}
      </g>
      <g>
        {animations.map(({ placeholderStyles, config }, index) => {
          return (
            <animated.g
              key={index}
              transform={placeholderStyles.scale.to(
                (value: number) =>
                  `translate(${config.position[orientation].x} ${config.position[orientation].y}) scale(${value})`,
              )}
              transform-origin="54 54"
              transform-box="fill-box"
            >
              <circle
                cx={50}
                cy={50}
                r="49.5"
                tw="fill-default stroke-on-default"
              />
              <path {...config.placeholder} tw="stroke-on-default" />
            </animated.g>
          );
        })}
      </g>
      {/* fail badges */}
      <g tw="stroke-on-default">
        {animations.map(({ failStyles, config }, index) => {
          return (
            <animated.path
              key={index}
              d="M39.1195 49.6465L17.7071 28.234L28.234 17.7071L49.6464 39.1195L50 39.4731L50.3536 39.1195L71.766 17.7071L82.2929 28.234L60.8805 49.6464L60.5269 50L60.8805 50.3536L82.2929 71.766L71.766 82.2929L50.3536 60.8805L50 60.5269L49.6464 60.8805L28.234 82.2929L17.7071 71.766L39.1195 50.3536L39.4731 50L39.1195 49.6465Z"
              transform={failStyles.scale.to(
                (value: number) =>
                  `translate(${config.position[orientation].x} ${config.position[orientation].y}) scale(${value})`,
              )}
              transform-origin="50 50"
              transform-box="fill-box"
            />
          );
        })}
      </g>
      {/* won badges */}
      <g>
        {animations.map(({ badgeStyles, config }, index) => {
          return (
            <animated.image
              key={index}
              {...config.won}
              width={108}
              height={108}
              transform={badgeStyles.scale.to(
                (value: number) =>
                  `translate(${config.position[orientation].x - 4} ${
                    config.position[orientation].y - 4
                  }) scale(${value})`,
              )}
              transform-origin="54 54"
              transform-box="fill-box"
            />
          );
        })}
      </g>
    </svg>
  );
}

export default Map;
