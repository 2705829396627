import { animated, useSpring } from "@react-spring/web";
import * as React from "react";
import "twin.macro";
import useTicktime from "../hooks/useTicktime";
import useTimeout from "../hooks/useTimeout";
import { Coordinates } from "./Map";

const timeRange: [number, number] = [16000, 22000];

export default function TripleArrow({ x, y, ...props }: Coordinates) {
  const getNextTick = useTicktime(...timeRange);
  const lastTick = useTimeout(getNextTick);
  const [{ show1, show2, show3 }, api] = useSpring(() => ({
    config: {
      //   duration: 1200,
    },
    from: {
      show1: 0,
      show2: 0,
      show3: 0,
    },
    to: [
      {
        show1: 1,
        show2: 0,
        show3: 0,
      },
      {
        show1: 1,
        show2: 1,
        show3: 0,
      },
      {
        show1: 1,
        show2: 1,
        show3: 1,
      },
    ],
  }));

  React.useEffect(() => {
    if (lastTick) {
      api.start({
        from: {
          show1: 0,
          show2: 0,
          show3: 0,
        },
        to: [
          {
            delay: 300,
            show1: 1,
            show2: 0,
            show3: 0,
          },
          {
            delay: 300,
            show1: 1,
            show2: 1,
            show3: 0,
          },
          {
            delay: 300,
            show1: 1,
            show2: 1,
            show3: 1,
          },
          {
            delay: 300,
            show1: 0,
            show2: 0,
            show3: 0,
          },
          {
            delay: 80,
            show1: 1,
            show2: 0,
            show3: 0,
          },
          {
            delay: 80,
            show1: 1,
            show2: 1,
            show3: 0,
          },
          {
            delay: 80,
            show1: 1,
            show2: 1,
            show3: 1,
          },
        ],
      });
    }
  }, [lastTick]);

  return (
    <g {...props} transform={`translate(${x} ${y})`}>
      <animated.path
        style={{
          opacity: show1.to((value: number) => {
            return Math.round(value);
          }),
        }}
        d="M1.5 2.36206L13 9.00159L1.5 15.6411L1.5 2.36206Z"
      />
      <animated.path
        style={{
          opacity: show2.to((value: number) => Math.round(value)),
        }}
        d="M18.834 2.36206L30.334 9.00159L18.834 15.6411L18.834 2.36206Z"
      />
      <animated.path
        style={{
          opacity: show3.to((value: number) => Math.round(value)),
        }}
        d="M36.167 2.36206L47.667 9.00159L36.167 15.6411L36.167 2.36206Z"
      />
    </g>
  );
}
