import * as React from "react";
import { useTrail, animated } from "@react-spring/web";
import "twin.macro";

function CircularWave({
  width = 1000,
  height = 1000,
  frequency = 22,
  ...props
}) {
  const [scale, setScale] = React.useState(1);
  const [timer, setTimer] = React.useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [trail, api] = useTrail(frequency, () => ({
    scale: 0,
    config: { mass: 1.6, tension: 500, friction: 40 },
  }));

  React.useEffect(() => {
    api.start({ scale });
  }, [scale]);

  React.useEffect(() => {
    //
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const handleClick = () => {
    setScale(1 + Math.random());
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => setScale(1), 100));
  };

  const middleX = width / 2,
    middleY = height / 2;
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      tw="text-secondary"
      onClick={handleClick}
      {...props}
    >
      {trail.map((styles, index) => (
        <animated.circle
          cx={middleX}
          cy={middleY}
          r={29 + 13 * index}
          tw="origin-center stroke-currentcolor stroke-[2px]"
          style={styles}
          key={index}
          fill="none"
        />
      ))}
    </svg>
  );
}

export default CircularWave;
