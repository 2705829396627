import * as React from "react";
import Star from "./Star";

const starArray = [0, 0, 0, 0, 0];

export default function Stars({
  amount = 0,
  small = false,
}: {
  amount: number;
  small: boolean;
}) {
  const shiftX = small ? 23 : 31;
  return (
    <React.Fragment>
      {starArray.map((_, index) => (
        <Star
          key={index}
          filled={index + 1 <= amount}
          x={shiftX * index}
          small={small}
        />
      ))}
    </React.Fragment>
  );
}
