import { animated, useSpring } from "@react-spring/web";
import * as React from "react";
import useOrientation from "../hooks/useOrientation";
import useTicktime from "../hooks/useTicktime";
import useTimeout from "../hooks/useTimeout";
import { Coordinates } from "./Map";

const distance = {
  landscape: 150,
  portrait: 100,
};

const timeRange: [number, number] = [5000, 14000];

export default function DoubleArrow({ x, y, ...props }: Coordinates) {
  const getNextTick = useTicktime(...timeRange);
  const lastTick = useTimeout(getNextTick);
  const orientation = useOrientation();
  const [{ x: transformX, opacity }, api] = useSpring(() => ({
    config: {
      mass: 3,
      tension: 300,
      friction: 60,
    },

    from: {
      x: x - distance[orientation],
      opacity: 0,
    },
    to: {
      x,
      opacity: 1,
    },
  }));

  React.useEffect(() => {
    if (lastTick) {
      api.start({
        from: {
          x,
          opacity: 1,
        },
        to: [
          {
            x: x + distance[orientation] * 3,
            opacity: 0,
          },
          {
            x: x - distance[orientation],
            opacity: 0,
          },
          {
            x,
            opacity: 1,
          },
        ],
      });
    }
  }, [lastTick]);

  return (
    <animated.g
      {...props}
      transform={transformX.to((value: number) => `translate(${value} ${y})`)}
      style={{ opacity }}
    >
      <path d="M0.99986 11.0886L9.82129 5.99929L0.99986 0.910003" />
      <path d="M11.1786 11.0886L20 5.99929L11.1786 0.910003" />
    </animated.g>
  );
}
