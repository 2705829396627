import * as React from "react";
export default function WinIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9795 3C19.8321 6.58582 18.8989 9.77138 17.5065 12.0921C15.9739 14.6464 13.996 16 12 16C10.004 16 8.02608 14.6464 6.49353 12.0921C5.1011 9.77138 4.1679 6.58582 4.02048 3H19.9795Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line
        x1="12"
        y1="16"
        x2="12"
        y2="21"
        stroke="currentColor"
        strokeWidth="2"
      />
      <line
        x1="6"
        y1="21"
        x2="18"
        y2="21"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M7.71015 13.7805L3.74702 14.6807C3.20846 14.803 2.6727 14.4655 2.55038 13.927L1.22148 8.07599C1.09916 7.53743 1.4366 7.00167 1.97516 6.87935L4.39789 6.32909"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M16.2899 13.7805L20.253 14.6807C20.7915 14.803 21.3273 14.4655 21.4496 13.927L22.7785 8.07599C22.9008 7.53743 22.5634 7.00167 22.0248 6.87935L19.6021 6.32909"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
