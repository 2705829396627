import * as React from "react";
import tw from "twin.macro";

const paths = [
  "M12.8393 1.61804L15.3947 9.48278L15.5069 9.82827H15.8702H24.1397L17.4495 14.689L17.1556 14.9025L17.2679 15.248L19.8233 23.1127L13.1332 18.252L12.8393 18.0385L12.5454 18.252L5.85522 23.1127L8.41063 15.248L8.52289 14.9025L8.22899 14.689L1.53884 9.82827H9.80832H10.1716L10.2839 9.48278L12.8393 1.61804Z",
  "M9.52316 1.69805L11.2957 7.15351L11.408 7.499H11.7713H17.5075L12.8668 10.8707L12.5729 11.0842L12.6852 11.4297L14.4577 16.8851L9.81705 13.5135L9.52316 13.2999L9.22927 13.5135L4.58858 16.8851L6.36117 11.4297L6.47342 11.0842L6.17953 10.8707L1.53884 7.499H7.27505H7.63832L7.75058 7.15351L9.52316 1.69805Z",
];

export default function Star({
  small,
  filled,
  x = 0,
}: {
  small: boolean;
  filled: boolean;
  x?: number;
}) {
  return (
    <path
      d={paths[small ? 1 : 0]}
      transform={`translate(${x} 0)`}
      css={[filled && tw`fill-secondary`]}
    />
  );
}
