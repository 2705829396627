import * as React from "react";

export default function useTimeout(nextTick = () => 1000) {
  const [lastChange, setLastChange] = React.useState(0);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    function tick() {
      setLastChange(Date.now());
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(tick, nextTick());
    }
    timeout = setTimeout(tick, nextTick());

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  return lastChange;
}
