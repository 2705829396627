import * as React from "react";
import "twin.macro";

export default function Music() {
  return (
    <React.Fragment>
      <circle cx="45" cy="15" r="12.5" />
      <circle cx="7.5" cy="34.5" r="6" />
      <circle cx="17.5" cy="7.5" r="6" />
      <mask id="path-4-inside-1_76_2222" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82 4H95V13H87V32C87 37.5228 82.5228 42 77 42C71.4772 42 67 37.5228 67 32C67 26.4772 71.4772 22 77 22C78.8214 22 80.5291 22.487 82 23.3378V13V9V4ZM77 37C79.7614 37 82 34.7614 82 32C82 29.2386 79.7614 27 77 27C74.2386 27 72 29.2386 72 32C72 34.7614 74.2386 37 77 37Z"
        />
      </mask>
      <path
        d="M95 4H96V3H95V4ZM82 4V3H81V4H82ZM95 13V14H96V13H95ZM87 13V12H86V13H87ZM82 23.3378L81.4993 24.2034L83 25.0716V23.3378H82ZM95 3H82V5H95V3ZM96 13V4H94V13H96ZM87 14H95V12H87V14ZM88 32V13H86V32H88ZM77 43C83.0751 43 88 38.0751 88 32H86C86 36.9706 81.9706 41 77 41V43ZM66 32C66 38.0751 70.9249 43 77 43V41C72.0294 41 68 36.9706 68 32H66ZM77 21C70.9249 21 66 25.9249 66 32H68C68 27.0294 72.0294 23 77 23V21ZM82.5007 22.4722C80.8818 21.5357 79.0019 21 77 21V23C78.641 23 80.1765 23.4383 81.4993 24.2034L82.5007 22.4722ZM81 13V23.3378H83V13H81ZM81 9V13H83V9H81ZM81 4V9H83V4H81ZM81 32C81 34.2091 79.2091 36 77 36V38C80.3137 38 83 35.3137 83 32H81ZM77 28C79.2091 28 81 29.7909 81 32H83C83 28.6863 80.3137 26 77 26V28ZM73 32C73 29.7909 74.7909 28 77 28V26C73.6863 26 71 28.6863 71 32H73ZM77 36C74.7909 36 73 34.2091 73 32H71C71 35.3137 73.6863 38 77 38V36Z"
        tw="fill-default"
        mask="url(#path-4-inside-1_76_2222)"
      />
    </React.Fragment>
  );
}
