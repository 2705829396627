import * as React from "react";
import * as queryString from "query-string";
import useStickyState from "../hooks/useStickyState";
import CircularWave from "../components/CircularWave";
import Map from "../components/Map";
import tw, { css } from "twin.macro";
import CampaignLogo from "../components/CampaignLogo";
import { rightAnswers } from "../data/game";
import Layout from "../components/Layout";
import Button from "../components/Button";
import WinIcon from "../components/icons/Win";
import CountdownVeil from "../components/CountdownVeil";
import MessageScreen from "../components/MessageScreen";

export type Station = { id: number; failed?: boolean; won?: boolean };

const initialState: Station[] = [];

const stations = Object.keys(rightAnswers);

// markup
/** @ts-ignore */
const IndexPage = ({ location }) => {
  const [mapVisible, setMapVisible] = React.useState(false);
  const [currentStation, setCurrentStation] = React.useState(0);
  const [score, setScore] = useStickyState("score", initialState);
  const [error, setError] = React.useState({ error: false, message: "" });
  const [showSubmitForm, setShowSubmitForm] = React.useState(false);
  const [formSend, setFormSend] = React.useState(false);
  const [thankyou, setThankyou] = React.useState(false);
  const [nickname, setNickname] = React.useState("");

  function showVeilAndThenShowMap() {
    setMapVisible(true);
  }

  function resetError() {
    setMapVisible(true);
    setError({ error: false, message: "" });
  }

  function resetSubmitForm() {
    setMapVisible(true);
    setShowSubmitForm(false);
  }

  function openSubmitForm() {
    setCurrentStation(0);
    setShowSubmitForm(true);
  }
  async function sendForm() {
    const body = "nickname=" + encodeURIComponent(nickname);
    const response = await fetch("/submit.php", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body,
    });
    setThankyou(true);
  }

  function finishSubmit() {
    setFormSend(true);
    setShowSubmitForm(false);
    setMapVisible(true);
    setCurrentStation(0);
  }

  React.useEffect(() => {
    if (!location.search) {
      setMapVisible(true);
      return;
    }
    const { round, answer } = queryString.parse(location.search);
    const parsedRound = parseInt(round as string, 10);
    const parsedAnswer = parseInt(answer as string, 10);
    if (
      isNaN(parsedRound) ||
      isNaN(parsedAnswer) ||
      !stations.includes(parsedRound.toString())
    ) {
      setError({ error: true, message: "Leider war der QR-Code ungültig." });
      return;
    }
    if (score.find((station: Station) => station.id === parsedRound)) {
      setError({
        error: true,
        message: `Du hast in Runde ${parsedRound} bereits mitgespielt.`,
      });
      return;
    }
    const result: Station = {
      id: parsedRound,
    };
    if (parsedAnswer !== rightAnswers[parsedRound]) {
      result.failed = true;
    } else {
      result.won = true;
    }
    setCurrentStation(parsedRound);
    setScore([...score, result]);
    showVeilAndThenShowMap();
  }, []);
  const stateById = score.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {},
  );

  const wonAnswers = Object.keys(rightAnswers).filter(
    (id) =>
      !!stateById[id as keyof typeof stateById] &&
      stateById[id as keyof typeof stateById].won,
  ).length;
  return (
    <Layout>
      {showSubmitForm && !formSend ? (
        thankyou ? (
          <MessageScreen onClose={finishSubmit}>
            <MessageScreen.Text>{`Danke ${nickname}.`}</MessageScreen.Text>
            <Button tw="mt-4 min-w-30" onClick={finishSubmit}>
              Zur Karte
            </Button>
          </MessageScreen>
        ) : (
          <MessageScreen onClose={resetSubmitForm}>
            <MessageScreen.Text>
              Reiche dein Ergebnis jetzt beim Gamemaster ein.
            </MessageScreen.Text>
            <div tw="relative h-7 w-[82.5%] max-w-[310px] mt-3">
              <input
                css={[
                  tw`block w-full h-full rounded-0.5 border-secondary border-[1px] bg-primary text-default px-2 font-16`,
                  css`
                    &:focus,
                    &:not(:placeholder-shown) {
                      + span {
                        transform: translate3d(0, 0, 0) scale(1);
                      }
                    }
                  `,
                ]}
                placeholder=" "
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              />
              <span
                css={[
                  tw`pointer-events-none absolute font-12 text-secondary transition-transform top--1 left-1.5 px-0.5 bg-primary`,
                  css`
                    transform: translate3d(12px, 26px, 0) scale(1.5);
                  `,
                ]}
              >
                Name
              </span>
            </div>
            <Button tw="mt-4 min-w-30" onClick={sendForm}>
              Jetzt abschicken
            </Button>
          </MessageScreen>
        )
      ) : error.error ? (
        <MessageScreen onClose={resetError}>
          <MessageScreen.Text>{error.message}</MessageScreen.Text>
          <Button tw="mt-4 min-w-30" onClick={resetError}>
            Zur Karte
          </Button>
        </MessageScreen>
      ) : (
        <CountdownVeil
          shallUnveil={mapVisible}
          seconds={currentStation > 0 ? 10 : 0}
        >
          <CampaignLogo tw="absolute left-2 top-2" />
          {mapVisible && (
            <Map
              station={currentStation}
              state={score}
              tw="absolute left-0 top-0 block w-full h-full z-10"
            />
          )}
          <CircularWave tw="absolute right-0 mr-[-500px] portrait:(top-[60%] mt-[-500px]) landscape:(bottom-0) mb-[-500px]" />
          {wonAnswers >= 5 && !formSend && (
            <Button
              tw="absolute right-2 bottom-2 z-50"
              onClick={openSubmitForm}
            >
              <WinIcon />
              <span>Mein Ergebnis einreichen</span>
            </Button>
          )}
        </CountdownVeil>
      )}
    </Layout>
  );
};

export default IndexPage;
