const isBrowser = typeof window !== "undefined";

export type JsonObject = {
  [Key in string]?: JsonValue | string | number | boolean | null;
};
export interface JsonArray extends Array<JsonValue> {}
export type JsonValue = JsonObject | JsonArray;

const getState = (key: string) => {
  const state = isBrowser ? window.localStorage.getItem(key) : null;
  if (state !== null) {
    return JSON.parse(state);
  }
  return null;
};

export { getState };

const setState = (key: string, value: JsonValue) => {
  if (isBrowser) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export { setState };
