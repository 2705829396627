import { animated, useSpring, useSprings } from "@react-spring/web";
import * as React from "react";
import useTimeout from "../hooks/useTimeout";
import tw from "twin.macro";
import CampaignLogo from "./CampaignLogo";

function Panel({ top = true, active = true, children, styles, ...props }) {
  return (
    <animated.div
      css={[
        tw`absolute left-0 bg-primary h-[48px] w-full z-50 overflow-hidden`,
        top ? tw`bottom-0` : tw`top-0`,
      ]}
      style={styles}
      {...props}
    >
      <div
        css={[
          tw`absolute left-0 h-[96px] w-full z-50 text-secondary font-100 text-center`,
          top ? tw`top-0` : tw`bottom-0`,
          !active &&
            tw`text-stroke-2 text-fill-transparent text-stroke-secondary`,
        ]}
      >
        {children}
      </div>
    </animated.div>
  );
}

function Wing({ top = true, spring, springs, items, current }) {
  return (
    <animated.div
      css={[
        tw`absolute left-0 bg-primary h-[50%] w-screen z-50`,
        top ? tw`bottom-[50%]` : tw`top-[50%]`,
      ]}
      style={spring}
    >
      {top && <CampaignLogo onPrimary tw="absolute left-2 top-2 z-50" />}
      {springs.map((styles, index) => (
        <Panel
          key={index}
          top={top}
          active={current === items[index]}
          styles={styles}
        >
          {items[index]}
        </Panel>
      ))}
    </animated.div>
  );
}

export default function CountdownVeil({
  shallUnveil = false,
  seconds,
  children,
  ...props
}: {
  shallUnveil?: boolean;
  seconds: number;
  children: React.ReactNode;
}) {
  const [currentSecond, setCurrentSecond] = React.useState(seconds);
  const [finish, setFinish] = React.useState(false);
  const [items, setItems] = React.useState(
    Array.from({ length: seconds }, (_, index) => seconds - index),
  );
  const lastTick = useTimeout(() => 1000);
  const [skipFirst, setSkipFirst] = React.useState(true);
  const springs = useSprings(
    seconds,
    items.map((item) => {
      const difference = Math.max(0, item - currentSecond);
      return {
        config: {
          mass: 0.3,
          tension: 300,
          friction: 16,
        },
        y: -48 * difference,
        zIndex: item,
      };
    }),
  );
  const springs2 = useSprings(
    seconds,
    items.map((item) => {
      const difference = Math.max(0, item - currentSecond);
      return {
        config: {
          mass: 0.3,
          tension: 300,
          friction: 16,
        },
        y: 48 * difference,
        zIndex: item,
      };
    }),
  );

  const wingSpring = useSpring({
    from: {
      y: "0vh",
    },
    to: {
      y: finish && shallUnveil ? "-50vh" : "0vh",
    },
  });
  const wingSpring2 = useSpring({
    from: { y: "0vh" },
    to: {
      y: finish && shallUnveil ? "50vh" : "0vh",
    },
  });

  React.useEffect(() => {
    if (skipFirst) {
      return setSkipFirst(false);
    }
    if (currentSecond > 0) {
      setCurrentSecond((was) => was - 1);
    }
  }, [lastTick]);

  React.useEffect(() => {
    setFinish(currentSecond < 1);
  }, [currentSecond]);

  React.useEffect(() => {
    setCurrentSecond(seconds);
    setItems(Array.from({ length: seconds }, (_, index) => seconds - index));
  }, [seconds]);

  return (
    <div tw="relative h-full w-full">
      {finish && shallUnveil && children}
      <Wing
        spring={wingSpring}
        springs={springs}
        items={items}
        current={currentSecond}
      />
      <Wing
        top={false}
        spring={wingSpring2}
        springs={springs2}
        items={items}
        current={currentSecond}
      />
    </div>
  );
}
