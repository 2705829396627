import * as React from "react";

import type { JsonValue } from "../services/persistence";
import { getState, setState } from "../services/persistence";

const isBrowser = typeof window !== "undefined";

const useStickyState = (key: string, initialValue: JsonValue) => {
  const [value, setValue] = React.useState(() => {
    if (!isBrowser) {
      return initialValue;
    }
    const stickyValue = getState(key);
    return stickyValue !== null ? stickyValue : initialValue;
  });
  React.useEffect(() => {
    if (isBrowser) {
      setState(key, value);
    }
  }, [key, value]);
  return [value, setValue];
};

export default useStickyState;
