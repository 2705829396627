import { animated, TrailComponentProps, useTrail } from "@react-spring/web";
import * as React from "react";
import useTicktime from "../hooks/useTicktime";
import useTimeout from "../hooks/useTimeout";
import { Coordinates } from "./Map";

const timeRange: [number, number] = [3000, 6000];

// @ts-ignore
const translate = (trail) => {
  return trail.y.to((value: number) => `translate(0 ${value})`);
};

export default function Trees({ x, y: initialY, ...props }: Coordinates) {
  const getNextTick = useTicktime(...timeRange);
  const lastTick = useTimeout(getNextTick);
  const [trail, api] = useTrail(2, () => ({
    config: {
      mass: 0.3,
      tension: 300,
      friction: 16,
    },
    y: 0,
  }));
  const [trail2, api2] = useTrail(2, () => ({
    config: {
      mass: 0.3,
      tension: 300,
      friction: 16,
    },
    y: 0,
  }));

  React.useEffect(() => {
    if (lastTick) {
      api.start({
        from: {
          y: 0,
        },
        to: [
          {
            y: -6,
          },
          {
            y: 0,
          },
        ],
      });
      api2.start({
        delay: 200,
        from: {
          y: 0,
        },
        to: [
          {
            y: -4,
          },
          {
            y: 0,
          },
        ],
      });
    }
  }, [lastTick]);

  return (
    <g {...props} transform={`translate(${x} ${initialY})`}>
      <path d="M28.9533 48.3112L43.8398 22.5272L58.7262 48.3112H28.9533Z" />
      <animated.path
        d="M28.9533 38.0476L43.8398 12.2635L58.7262 38.0476H28.9533Z"
        transform={translate(trail[0])}
      />
      <animated.path
        d="M28.9533 27.7841L43.8398 2L58.7262 27.7841H28.9533Z"
        transform={translate(trail[1])}
      />
      <path d="M2.24476 39.9053L10.291 25.9687L18.3373 39.9053H2.24476Z" />
      <animated.path
        d="M2.24476 34.0984L10.291 20.1618L18.3373 34.0984H2.24476Z"
        transform={translate(trail2[0])}
      />
      <animated.path
        d="M2.24476 28.2916L10.291 14.355L18.3373 28.2916H2.24476Z"
        transform={translate(trail2[1])}
      />
    </g>
  );
}
